import Http from '@/services/Http'
import BaseService from '@/services/BaseService'
import httpBuildQuery from 'http-build-query'

const urlAPI = process.env.VUE_APP_APIENDPOINT

export const BookingService = {
  formatNewForm (data) {
    let formatedData = {}

    formatedData.tipoOperacao = data.tipoOperacao.id
    formatedData.idPortoOrigem = data.origem.id
    formatedData.idPortoDestino = data.destino.id
    formatedData.situacao = data.situacao.id
    formatedData.semana = data.semana

    if (data.etd) {
      formatedData.etd = data.etd
    }

    if (data.ddl) {
      formatedData.deadLine = data.ddl
    }

    if (data.dataSolicitacao) {
      formatedData.dataSolicitacao = data.dataSolicitacao
    }

    formatedData.possuiMulta = data.possuiMulta
    formatedData.valorMulta = data.valorMulta
    formatedData.numero = data.numero
    formatedData.observacao = data.observacao

    formatedData.idcompanhiaTransporte = (data.armador) ? data.armador.id : null
    formatedData.idCliente = (data.cliente) ? data.cliente.id : null
    formatedData.idLogisticaHouse = (data.processo) ? data.processo.id : null
    formatedData.idVendedor = (data.vendedor) ? data.vendedor.id : null
    formatedData.idSolicitante = (data.solicitante) ? data.solicitante.id_table_origin : null
    formatedData.idNavio = (data.navio) ? data.navio.id : null
    formatedData.idMercadoria = (data.mercadoria) ? data.mercadoria.id : null
    formatedData.idcontratoFrete = (data.contrato) ? data.contrato.id : null

    formatedData.equipamentos = data.equipamentos.map(x => ({
      idequipamentoMaritimo: x.id,
      quantidade: x.quantity
    }))

    return formatedData
  },

  formatNewRequestForm (data) {
    let formatedData = {}
    formatedData.tipoOperacao = data.tipoOperacao.id
    formatedData.idPortoOrigem = data.origem.id
    formatedData.idPortoDestino = data.destino.id
    formatedData.observacao = data.observacao

    formatedData.idCliente = (data.cliente) ? data.cliente.id : null
    formatedData.idVendedor = (data.vendedor) ? data.vendedor.id : null
    formatedData.idMercadoria = (data.mercadoria) ? data.mercadoria.id : null
    formatedData.idLogisticaHouse = (data.processo) ? data.processo.id : null

    formatedData.equipamentos = data.equipamentos.map(x => ({
      idequipamentoMaritimo: x.id,
      quantidade: x.quantity
    }))

    return formatedData
  },

  /**
   * http://localhost/api/v2/booking
   *
   * @param filter
   * @returns {Promise<any>}
   */
  getBookings (filter) {
    let queryString = httpBuildQuery(filter)

    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/booking?${queryString}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  /**
   * http://localhost/api/v2/booking/{id}
   *
   * @param id
   * @returns {Promise<any>}
   */
  getBooking (id) {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/booking/${id}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  postNewBooking (data) {
    let formatedData = this.formatNewForm(data)
    try {
      return new Promise(function (resolve, reject) {
        Http.post(`${urlAPI}v2/booking`, formatedData).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  /**
   * @param {int} id
   * @param {*} data
   * @returns booking
   */
  putBooking (id, data) {
    let formatedData = this.formatNewForm(data)
    try {
      return new Promise(function (resolve, reject) {
        Http.put(`${urlAPI}v2/booking/${id}`, formatedData).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  /**
   * @returns {Promise<any>}
   */
  getStatusOptions () {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/booking/status/list`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  /**
   * Return active shipOwners
   *
   * @param filter
   * @returns {Promise<unknown>}
   */
  getShipOwners (filter) {
    let queryString = httpBuildQuery(filter)

    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/booking/shipowner?${queryString}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  /**
   * Return active shipOwners
   *
   * @param filter
   * @returns {Promise<unknown>}
   */
  getVesselsByShipOwner (idShipOwner, filter) {
    let queryString = httpBuildQuery(filter)

    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/booking/${idShipOwner}/vessels?${queryString}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  /**
   * Return active shipOwners
   *
   * @param filter
   * @returns {Promise<unknown>}
   */
  getProcessesByCustomer (idCustomer, filter) {
    let queryString = httpBuildQuery(filter)

    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/booking/${idCustomer}/processes?${queryString}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  postNewRequest (data) {
    let formatedData = this.formatNewRequestForm(data)
    try {
      return new Promise(function (resolve, reject) {
        Http.post(`${urlAPI}v2/booking/request`, formatedData).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  sendMessage (data) {
    try {
      return new Promise(function (resolve, reject) {
        Http.post(`${urlAPI}v2/booking/comment`, data).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  deleteBooking (id) {
    try {
      return new Promise(function (resolve, reject) {
        Http.delete(`${urlAPI}v2/booking/${id}`).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data, httpCode: res.status })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(new Error('formulário invalido'))
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  /**
   * /api/v2/booking/update-status
   *
   * @param contentData
   * @returns {Promise<any>}
   */
  updateBookingStatus (data) {
    try {
      return new Promise(function (resolve, reject) {
        Http.post(`${urlAPI}v2/booking/update/status`, data).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  /**
   * http://localhost/api/V1/booking/{id}/subscribers
   *
   * @param id
   * @returns {Promise<any>}
   */
  getBookingSubscribers (id, filter) {
    let queryString = httpBuildQuery(filter)

    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/booking/${id}/subscribers?${queryString}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  /**
   * http://localhost/api/V1/booking/subscribe
   *
   * @param data
   * @returns {Promise<any>}
   */
  toogleSubscribe (data) {
    try {
      return new Promise(function (resolve, reject) {
        Http.post(`${urlAPI}v2/booking/subscribe`, data).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  /**
   * http://localhost/api/V1/booking/unsubscribe
   *
   * @param data
   * @returns {Promise<any>}
   */
  unsubscribe (data) {
    try {
      return new Promise(function (resolve, reject) {
        Http.post(`${urlAPI}v2/booking/unsubscribe`, data).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  /**
   * @param {*} data
   * @returns array
   */
  putSubscribers (data) {
    try {
      return new Promise(function (resolve, reject) {
        Http.put(`${urlAPI}v2/booking/subscribers`, data).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  /**
   * http://localhost/api/V1/booking/apply
   *
   * @param data
   * @returns {Promise<any>}
   */
  applyBooking (data) {
    try {
      return new Promise(function (resolve, reject) {
        Http.post(`${urlAPI}v2/booking/apply`, data).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
    // },

  // applyBooking (data) {
  //   try {
  //     return new Promise(function (resolve, reject) {
  //       Http.post(`${urlAPI}v2/booking/apply`, data).then(function (res) {
  //         if (BaseService.validateResponseReturn(res)) {
  //           resolve({ data: res.data })
  //         }
  //       }).catch(function (err) {
  //         BaseService.checkSesssionExpired(err)
  //       })
  //     })
  //   } catch (err) {
  //     BaseService.showMessageError(err)
  //   }
  },

  /**
   * http://localhost/api/V1/booking/{id}/equipments
   *
   * @param id
   * @returns {Promise<any>}
   */
  getBookingEquipments (id, filter) {
    let queryString = httpBuildQuery(filter)

    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/booking/${id}/equipments?${queryString}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  /**
   * http://localhost/api/V1/booking/managers
   *
   * @returns {Promise<any>}
   */
  getManagers () {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/booking/managers`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  /**
   * http://localhost/api/V1/booking/requesters
   *
   * @returns {Promise<any>}
   */
  getRequesters () {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/booking/requesters`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  /**
   * http://localhost/api/V1/booking/notifiables
   *
   * @returns {Promise<any>}
   */
  getNotifiables () {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/booking/notifiables`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  postConfig (data) {
    // let formatedData = this.formatNewForm(data)
    try {
      return new Promise(function (resolve, reject) {
        Http.post(`${urlAPI}v2/booking/config`, data).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  leaveBooking (data) {
    try {
      return new Promise(function (resolve, reject) {
        Http.post(`${urlAPI}v2/booking/leave`, data).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data, httpCode: res.status })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(new Error('formulário invalido'))
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  /**
   * @param filter
   * @returns {Promise<unknown>}
   */
  getContractOptions (filter) {
    let queryString = httpBuildQuery(filter)

    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/contrato-frete/options?${queryString}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  /**
   * http://localhost/api/V1/booking/merge/options
   *
   * @param booking
   * @returns {Promise<any>}
   */
  getMergeOptions (id) {
    let queryString = httpBuildQuery(id)

    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/booking/merge/options?${queryString}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  /**
   * http://localhost/api/V1/booking/merge
   *
   * @param data
   * @returns {Promise<any>}
   */
  mergeBookings (data) {
    try {
      return new Promise(function (resolve, reject) {
        Http.post(`${urlAPI}v2/booking/merge`, data).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  formatNewWeekForm (data) {
    let formatedData = {}

    formatedData.tipoOperacao = 0
    // formatedData.situacao = data.situacao.id
    formatedData.semana = data.semana
    formatedData.teusContratados = data.teus
    formatedData.etd = data.etd
    formatedData.deadLine = data.ddl
    formatedData.possuiMulta = data.possuiMulta
    formatedData.valorMulta = data.valorMulta
    formatedData.observacao = data.observacao
    formatedData.idcontratoFrete = data.contrato.id

    return formatedData
  },

  postNewWeek (data) {
    let formatedData = this.formatNewWeekForm(data)
    try {
      return new Promise(function (resolve, reject) {
        Http.post(`${urlAPI}v2/booking/contract`, formatedData).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  /**
   * @param {int} id
   * @param {*} data
   * @returns booking
   */
  putWeek (id, data) {
    let formatedData = this.formatNewWeekForm(data)
    try {
      return new Promise(function (resolve, reject) {
        Http.put(`${urlAPI}v2/booking/${id}/contract`, formatedData).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  /**
   * @param filter
   * @returns {Promise<unknown>}
   */
  getContractListedProcesses (bookingId, filter) {
    let queryString = httpBuildQuery(filter)

    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/booking/${bookingId}/contract/listed/processes?${queryString}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  /**
   * @param filter
   * @returns {Promise<unknown>}
   */
  getGroupWeekTotal (bookingId, filter) {
    let queryString = httpBuildQuery(filter)

    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/booking/${bookingId}/group/week/total?${queryString}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  /**
   * http://localhost/api/v2/booking/{id}
   *
   * @param id
   * @returns {Promise<any>}
   */
  getBookingContractGroup (id) {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/booking/${id}/contract/group`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  formatContractGroupForm (data) {
    let formatedData = {}

    formatedData.nome = data.nome
    if (data.observacao) {
      formatedData.observacao = data.observacao
    }

    formatedData.itens = data.itens.map(x => ({
      idcontratoFrete: x.id
    }))

    return formatedData
  },

  postBookingContractGroup (data) {
    let formatedData = this.formatContractGroupForm(data)
    try {
      return new Promise(function (resolve, reject) {
        Http.post(`${urlAPI}v2/booking/contract/group`, formatedData).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  /**
   * @param {int} id
   * @param {*} data
   * @returns booking
   */
  putBookingContractGroup (id, data) {
    let formatedData = this.formatContractGroupForm(data)
    try {
      return new Promise(function (resolve, reject) {
        Http.put(`${urlAPI}v2/booking/${id}/contract/group`, formatedData).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },

  deleteBookingContractGroup (id) {
    try {
      return new Promise(function (resolve, reject) {
        Http.delete(`${urlAPI}v2/booking/${id}/contract/group`).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data, httpCode: res.status })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(new Error('formulário invalido'))
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  getContractFilterOptions () {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/booking/contract/filter/options`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  }
}
export default BookingService
