<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <form method="post" @submit.prevent="onSubmit('frmBooking')" data-vv-scope="frmBooking" novalidate="novalidate">
      <div class="row justify-content-md-center pt-2 px-2">
        <div class="col-md-12 px-2">
          <div class="card card-fullheight">
            <div class="card-body p-2">
              <h5 class="box-title m-3">{{ $t('booking.new-edit_contract') }}</h5>
              <hr>

              <!-- Operação, Origem e Destino -->
              <div class="row custom-row">

                <!--  Contrato  -->
                <div class="col-lg-9 form-group px-2">
                  <label class="small grey-label">Contrato de Frete</label>
                  <v-select class="grey-field" name="booking.contrato" label="nome"
                    :clearable="false" :searchable="true" v-model="booking.contrato"
                    :options="contractList" @search="contractFetchOptions"
                    v-validate="{ required: true }" :filter-by="filterBy">
                    <template slot="no-options" slot-scope="{searching, search }">
                      <em v-if="!searching" style="opacity: 0.5;">
                        Digite para buscar um contrato
                      </em>
                      <em v-else style="opacity: 0.5;">
                        Nenhum contrato encontrado para {{ search }}
                      </em>
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.nome }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.nome }}
                      </div>
                    </template>
                  </v-select>
                  <transition name="slide" mode="">
                    <small v-if="errors.first('booking.contrato')" class="royalc-error-field">{{ errors.first('booking.contrato') }}</small>
                  </transition>
                </div>

                <!--  Semana  -->
                <div class="col-lg-3 col-md-6 form-group px-2">
                  <label class="small grey-label mb-0">Semana</label>
                  <custom-input
                    v-model="booking.semana"
                    name="booking.semana"
                    type="number"
                    rootClassName="md-form my-0 py-0"
                    inputClassName="md-form-control text-center"
                    :error="submitted ? errors.first('booking.semana') : ''"
                    v-validate="{ required: true }"
                    @blur="onLostFocus()">
                  </custom-input>
                </div>

                <!--  TEUS  -->
                <div class="col-lg-3 col-md-6 form-group px-2">
                  <label class="small grey-label mb-0">TEUS</label>
                  <custom-input
                    v-model="booking.teus"
                    name="booking.teus"
                    type="number"
                    rootClassName="md-form my-0 py-0"
                    inputClassName="md-form-control text-center"
                    :error="submitted ? errors.first('booking.teus') : ''"
                    v-validate="{ required: true }"
                    @blur="teusOnLostFocus()">
                  </custom-input>
                </div>

                <!--  Multa  -->
                <div class="col-lg-3 col-md-6 form-group px-2 ">
                  <label class="small grey-label mb-0">Multa</label>
                  <label class="ui-switch no-margin small switch-solid switch-warning pl-2">
                    <input id="possuiMulta" value="1" type="checkbox"
                      ref="possuiMulta" v-model="booking.possuiMulta"
                      :checked="booking.possuiMulta" @change="clearCharge()"><span> </span>
                  </label>
                  <input type="number" min="0" step=".01" class="md-form-control text-center"
                    v-model="booking.valorMulta"
                    :disabled="!booking.possuiMulta"
                    :error="submitted ? errors.first('booking.valorMulta') : ''">
                </div>

                <!--  ETD  -->
                <div class="col-lg-3 col-md-6 form-group px-2">
                    <custom-input-date
                      v-model="booking.etd"
                      name="booking.etd"
                      label="ETD"
                      type="text"
                      v-on:input="booking.etd = $event"
                      v-validate="{ required: false }"
                      :error="errors.first('booking.etd')">
                    </custom-input-date>
                </div>

                <!--  Deadline  -->
                <div class="col-lg-3 col-md-6 form-group px-2">
                  <custom-input-date
                    v-model="booking.ddl"
                    name="booking.ddl"
                    label="Deadline"
                    type="text"
                    v-on:input="booking.ddl = $event"
                    v-validate="{ required: false }"
                    :error="errors.first('booking.ddl')">
                  </custom-input-date>
                </div>

                <!-- Observação -->
                <div class="col-md-12 mt-2 px-2">
                  <label class="mb-2">Observação</label>
                  <custom-textarea
                    v-model="booking.observacao"
                    name="booking.observacao"
                    type="text"
                    rootClassName="md-form m-0"
                    inputClassName="md-form-control mt-0"
                    :rows="3"
                    v-validate="{ required: false }"
                    :error="errors.first('booking.observacao')">
                  </custom-textarea>
                </div>
              </div>

              <hr>

              <div class="row custom-row">
                <!-- Submit -->
                <div class="col-12 m-0 p-0">
                  <button type="button" class="btn btn-sm btn-outline-light pull-right ml-2" @click="onSave">
                    Salvar
                  </button>
                  <button type="button" class="btn btn-link btn-sm pull-right" @click="onCancel">
                    Cancelar
                  </button>
                  <router-link v-show="!isEdit"
                    :to="{name: 'BookingNew'}" class="btn btn-link btn-sm mt-1 pull-left">
                    Por equipamento
                  </router-link>
                  <!-- <button type="button" class="btn btn-link btn-sm mt-1 pull-right" @click="onShow">
                    Console
                  </button> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
// Components
import CustomTextarea from '@/components/Forms/CustomTextarea.vue'
import CustomInputDate from '@/components/Forms/CustomInputDate.vue'
import CustomInput from '@/components/Forms/CustomInput.vue'

// eslint-disable-next-line
import { util } from '@/utils/utils.js'

// Form
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'

// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

// vue-select
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

// Services
import BookingService from '@/services/BookingService'

export default {
  name: 'BookingForm',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('booking.new-edit_booking') + ' - %s'
    }
  },
  data () {
    return {
      processEndpoint: process.env.VUE_APP_APIENDPOINT + 'v2/Booking',
      isLoading: true,
      isEdit: false,
      formErrors: {},
      fullPage: true,
      submitted: false,
      //  Selected Values (Form)
      booking: {
        armador: null,
        cliente: null,
        vendedor: null,
        solicitante: null,
        processo: null,
        tipoOperacao: {
          id: 2,
          name: 'IMPORT'
        },
        origem: null,
        destino: null,
        navio: null,
        mercadoria: null,
        equipamentos: [],
        numero: null,
        situacao: {
          id: 0,
          label: 'Disponível'
        },
        etd: null,
        ddl: null,
        possuiMulta: null,
        valorMulta: null,
        observacao: null,
        dataCriacao: null,
        dataSolicitacao: null,
        send_email: 0,
        contrato: null,
        semana: null,
        teus: null
      },
      // List of options to select on select input
      operationOptions: [
        {
          id: 2,
          name: 'IMPORT'
        },
        {
          id: 1,
          name: 'EXPORT'
        }
      ],
      bookingList: [],
      statusList: [
        {
          id: 0,
          label: 'Disponível'
        },
        {
          id: 1,
          label: 'Completo'
        },
        {
          id: 3,
          label: 'Overbooking'
        }
      ],
      employeeList: [],
      sellerList: [],
      originList: [],
      customerList: [],
      destinationList: [],
      commoditieList: [],
      shipOwnerList: [],
      vesselList: [],
      travelList: [],
      contractList: [],
      equipmentList: [],
      processList: [],
      delay: 400
    }
  },
  components: {
    Loading,
    vSelect,
    CustomTextarea,
    CustomInputDate,
    CustomInput
  },
  // Apply filters to local filter
  beforeMount () {

  },
  mounted () {
    this.$store.dispatch('SetShowFilter', false)
    this.$store.dispatch('setShowSearch', false)
  },
  created () {
    let _this = this
    _this.isEdit = _this.$route.name === 'BookingContractEdit'
    let bookingId = this.$route.params.id

    // this.getStatusOptions()

    if (bookingId) {
      BookingService.getBooking(bookingId).then(res => {
        let data = res.data.data
        let edit = this.booking

        edit.id = bookingId

        edit.situacao = this.statusList.find(x => parseInt(x.id) === data.situacao)

        if (this.$util.isset(data.idcontrato_frete)) {
          edit.contrato = {
            id: data.idcontrato_frete.idcontrato_frete,
            id_table_origin: data.idcontrato_frete.id_table_origin,
            nome: data.idcontrato_frete.numero_contrato
          }
        }

        edit.semana = data.semana
        edit.teus = data.teus_contratados

        if (this.$util.isset(data.dead_line)) {
          edit.ddl = data.dead_line
        }

        edit.equipamentos = data.equipamentos.map(x => {
          return {
            id: x.idequipamento_maritimo.id,
            descricao: x.idequipamento_maritimo.descricao,
            description: x.idequipamento_maritimo.descricao,
            quantity: x.quantidade
          }
        })

        edit.etd = data.etd
        edit.dataSolicitacao = data.data_solicitacao
        edit.possuiMulta = data.possui_multa
        edit.valorMulta = parseFloat(data.valor_multa)
        edit.observacao = data.observacao

        this.booking = edit
      })
    }

    _this.isLoading = false
  },
  methods: {
    displayErrors (errors) {
      let formatedErrors = util.getFormErrors(errors)
      this.formErrors = formatedErrors
    },
    showNumber (number) {
      return util.numberFormat(number, 2, ',', '.')
    },
    onSelectEmployee (val) {
      this.Booking.employee = val
    },
    mountLink (itemName, object) {
      return this.$router.resolve({ name: itemName, params: { id_Booking: object.id } }).href
    },
    getStatusOptions () {
      let _this = this
      _this.showProcessingMessage = true
      _this.disabled = true
      BookingService.getStatusOptions(false).then(res => {
        _this.statusList = Object.entries(res.data.data).map((key) => {
          return {
            id: key[0],
            label: key[1]
          }
        })
        _this.showProcessingMessage = false
        _this.disabled = false
      }).catch(() => {
        _this.showProcessingMessage = false
        _this.disabled = false
      })
    },
    contractFetchOptions (search, loading) {
      if (search.length > 0) {
        let _this = this
        window.clearTimeout(this.timer)

        this.timer = window.setTimeout(() => {
          loading(true)

          let filters = {
            'searchTerm': search
          }

          BookingService.getContractOptions(filters).then(res => {
            _this.contractList = res.data.data
            loading(false)
          })
        }, this.delay)
      }
    },
    onCancel (e) {
      var _this = this
      _this.$router.push({ name: 'BookingContractIndex' })
    },
    onSave (e) {
      var _this = this
      this.submitted = true
      _this.$validator.validate().then(valid => {
        if (valid) {
          _this.isLoading = true

          if (this.isEdit) {
            BookingService.putWeek(this.booking.id, this.booking).then(response => {
              _this.$router.push({ name: 'BookingContractIndex' })
            }).catch(err => {
              if (err.code === 400) {
                _this.displayErrors(err.errors)
              }
              _this.isLoading = false
            })
          } else {
            BookingService.postNewWeek(this.booking).then(response => {
              _this.$router.push({ name: 'BookingContractIndex' })
            }).catch(err => {
              if (err.code === 400) {
                _this.displayErrors(err.errors)
              }
              _this.isLoading = false
            })
          }
        }
      })
    },
    setOperation (obj) {
      if (this.booking.tipoOperacao.id !== obj.id) {
        this.booking.origem = {
          id: null,
          nome: null
        }
        this.booking.destino = {
          id: null,
          nome: null
        }
      }
      this.booking.tipoOperacao = obj
    },
    onShow () {
    },
    clearCharge () {
      if (!this.booking.possuiMulta) {
        this.booking.valorMulta = null
      }
    },
    onLostFocus () {
      if (this.booking.semana === '') {
        return
      }

      if (this.booking.semana > 52) {
        this.booking.semana = 52
      }

      if (this.booking.semana < 1) {
        this.booking.semana = 1
      }
    },
    teusOnLostFocus () {
      if (this.booking.teus < 1) {
        this.booking.teus = 1
      }
    },
    updateProcessFields () {
    },
    filterBy (option, label, search) {
      let splited = search.toLocaleLowerCase().split(' ')
      let lowerLabel = label.toLocaleLowerCase()

      return splited.reduce((founded, term) => {
        founded += lowerLabel.indexOf(term) > -1
        return founded
      })
    }
  },
  computed: {
    equipamentsError () {
      return this.submitted && this.booking.equipamentos.length <= 0
    }
  }
}
</script>

<style scoped>
  .swal2-popup {
    width: inherit !important;
  }

  .card-body {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .md-form-control {
    height: 30px;
  }

  .v-select.md-form-control {
    height: 35px;

  }

  td.editable {
    padding: 0;
    vertical-align: middle;
  }

  td.editable input {
    background-color: inherit;
    border: 0;
    width: 100%;
    min-height: 33px;
    box-sizing: border-box;
  }

  .card-subtitle {
    margin-top: 15px;
  }

</style>
